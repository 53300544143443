(function ($, window, document, undefined) {
	var AMContactus = (function () {
		var select2Subject = null;

		var initSelect2 = function () {
			select2Subject = $("select[name=select-subject]").select2({
				language: "he",
				dropdownParent: $("select[name=select-subject]").parent(),
			});
		};

		return {
			/**
			 * инициализация модуля
			 */
			init: function () {
				initSelect2();
			},
		};
	})();

	// Export
	window.AMContactus = AMContactus;
})(jQuery, this, this.document);
