"use strict";

(function ($, window, document, undefined) {
	$(function () {
		// Импорт модуля
		// var CMYCSeoSlider = window.CMYCSeoSlider;
		// var CMYCBegining = window.CMYCBegining;
		// var CMYCComments = window.CMYCComments;
		var AMProducts = window.AMProducts;
		var AMDetails = window.AMDetails;
		var AMLogin = window.AMLogin;
		var AMCheckout = window.AMCheckout;
		var AMAdress = window.AMAdress;
		var AMOrders = window.AMOrders;
		var AMContactus = window.AMContactus;
		var AMFooter = window.AMFooter;

		// Вызов публичного метода модуля
		AMProducts.init();
		AMDetails.init();
		AMLogin.init();
		AMCheckout.init();
		AMAdress.init();
		AMOrders.init();
		AMContactus.init();
		AMFooter.init();
		// CMYCSeoSlider.init();
		// CMYCBegining.init();
		// CMYCComments.init();

		//$.fancybox.destroy();
		$("[data-fancybox]").fancybox({
			afterShow: function (instance, slide) {
				if (slide.$slide.find("video").length > 0) {
					slide.$slide.find("video")[0].play();
				}
			},
			beforeClose: function (instance, slide) {
				if (slide.$slide.find("video").length > 0) {
					slide.$slide.find("video")[0].pause();
				}
			},
		});

		Ya.share2("NAME-PROJECT-my-share", {
			theme: {
				services: "facebook,vkontakte,twitter,moimir,viber",
				counter: true,
				lang: "ru",
				bare: false,
			},
			hooks: {
				onready: function () {
					var timer = setInterval(function () {
						var empty = false;
						$("#NAME-PROJECT-my-share .ya-share2__counter").each(
							function (i) {
								if ($(this).text().length == 0) {
									empty = true;
								}
							}
						);

						if (empty === false) {
							$(
								"#NAME-PROJECT-my-share .ya-share2__counter"
							).each(function (i) {
								if (parseInt($(this).text()) < 30) {
									$(this).text("");
									$(this).hide();
								}
							});
							clearInterval(timer);
						}
					}, 100);
				},
			},
		});

		Ya.share2("ms-my-share--mobile", {
			theme: {
				services: "facebook,vkontakte,twitter,moimir,viber",
				counter: false,
				lang: "ru",
				bare: false,
			},
		});

		$.fancybox.defaults.hash = false;
		$.fancybox.defaults.buttons = [
			"slideShow",
			"fullScreen",
			"thumbs",
			"close",
		];

		$.fancybox.defaults.i18n = {
			en: {
				CLOSE: "Закрыть",
				NEXT: "Дальше",
				PREV: "Назад",
				ERROR: "Не удается загрузить. <br/> Попробуйте позднее.",
				PLAY_START: "Начать слайдшоу",
				PLAY_STOP: "Остановить слайдшоу",
				FULL_SCREEN: "На весь экран",
				THUMBS: "Превью",
			},
		};
	});
})(jQuery, this, this.document);
