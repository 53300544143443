(function ($, window, document, undefined) {
	var AMOrders = (function () {
		var initAccordion = function () {
			$(".am-orders__accordion-head").click(function () {
				if ($(this).parent().hasClass("am-orders__accordion--active")) {
					$(this).siblings(".am-orders__accordion-body").slideUp();
					$(this)
						.parent()
						.removeClass("am-orders__accordion--active");
				} else {
					$(this).siblings(".am-orders__accordion-body").slideDown();
					$(this).parent().addClass("am-orders__accordion--active");
				}
			});
		};

		var initAccordionBySteps = function () {
			$(".am-orders__steps-head").click(function () {
				if ($(this).hasClass("am-orders__steps-head--active")) {
					$(this).siblings(".am-orders__steps-body").slideUp();
					$(this).removeClass("am-orders__steps-head--active");
				} else {
					$(this).siblings(".am-orders__steps-body").slideDown();
					$(this).addClass("am-orders__steps-head--active");
				}
			});
		};

		return {
			/**
			 * инициализация модуля
			 */
			init: function () {
				initAccordion();
				initAccordionBySteps();
			},
		};
	})();

	// Export
	window.AMOrders = AMOrders;
})(jQuery, this, this.document);
