(function ($, window, document, undefined) {
	var AMProducts = (function () {
		var initRangeInput = function () {
			var parent = document.querySelector("#rangeSlider");
			if (!parent) return;

			var rangeS = parent.querySelectorAll("input[type=range]"),
				numberS = parent.querySelectorAll("input[type=number]");

			rangeS.forEach(function (el) {
				el.oninput = function () {
					var slide1 = parseFloat(rangeS[0].value),
						slide2 = parseFloat(rangeS[1].value);

					if (slide1 > slide2) {
						[slide1, slide2] = [slide2, slide1];
						// var tmp = slide2;
						// slide2 = slide1;
						// slide1 = tmp;
					}

					numberS[0].value = slide1;
					numberS[1].value = slide2;
				};
			});

			numberS.forEach(function (el) {
				el.oninput = function () {
					var number1 = parseFloat(numberS[0].value),
						number2 = parseFloat(numberS[1].value);

					if (number1 > number2) {
						var tmp = number1;
						numberS[0].value = number2;
						numberS[1].value = tmp;
					}

					rangeS[0].value = number1;
					rangeS[1].value = number2;
				};
			});
		};

		let initTabs = function () {
			$(".am-products__tabs-item").click(function () {
				let active = $(this).attr("href");

				$(".am-products__tab").removeClass("am-products__tab--active");
				$(".am-products__tabs-item").removeClass(
					"am-products__tabs-item--active"
				);

				$(this).addClass("am-products__tabs-item--active");
				$(active).addClass("am-products__tab--active");

				return false;
			});
		};

		return {
			/**
			 * инициализация модуля
			 */
			init: function () {
				initRangeInput();
				initTabs();
			},
		};
	})();

	// Export
	window.AMProducts = AMProducts;
})(jQuery, this, this.document);
