(function ($, window, document, undefined) {
	var AMDetails = (function () {
		var initAccordion = function () {
			$(".am-details__info-accordion-title").click(function () {
				// console.log("click", this);
				if (
					$(this).hasClass("am-details__info-accordion-title--active")
				) {
					$(this)
						.siblings(".am-details__info-accordion-dscr")
						.slideUp();
					$(this).removeClass(
						"am-details__info-accordion-title--active"
					);
				} else {
					$(this)
						.siblings(".am-details__info-accordion-dscr")
						.slideDown();
					$(this).addClass(
						"am-details__info-accordion-title--active"
					);
				}
			});
		};

		var swiper_image;

		var initCarousel = function () {
			swiper_image = new Swiper(".am-details__thumbs", {
				slidesPerView: 6,
				slidesPerColumn: 1,
				direction: "vertical",
				spaceBetween: 20,
				loop: true,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				breakpoints: {
					// 1200: {
					// 	slidesPerView: 5,
					// 	spaceBetween: 15,
					// },
					// 640: {
					// 	spaceBetween: 0,
					// },
				},
			});

			new Swiper(".am-details__events", {
				slidesPerView: 1,
				loop: true,
				spaceBetween: 30,
				navigation: {
					nextEl: ".am-details__next",
					prevEl: ".am-details__prev",
				},
				thumbs: {
					swiper: swiper_image,
				},
				pagination: {
					el: ".am-details__pagination",
					type: "bullets",
				},
				breakpoints: {
					// 1200: {
					// 	slidesPerView: 5,
					// 	spaceBetween: 15,
					// },
					640: {
						// spaceBetween: 0,
					},
				},
			});
		};

		return {
			/**
			 * инициализация модуля
			 */
			init: function () {
				initCarousel();
				initAccordion();
			},
		};
	})();

	// Export
	window.AMDetails = AMDetails;
})(jQuery, this, this.document);
