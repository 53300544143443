(function ($, window, document, undefined) {
	var AMFooter = (function () {
		var initAccordion = function () {
			$(".am-footer__top-links-title").click(function () {
				// console.log("click", this);
				if ($(this).hasClass("am-footer__top-links-title--active")) {
					$(this).siblings("li").slideUp();
					$(this).removeClass("am-footer__top-links-title--active");
				} else {
					$(this).siblings("li").slideDown();
					$(this).addClass("am-footer__top-links-title--active");
				}
			});
			return false;
		};

		return {
			/**
			 * инициализация модуля
			 */
			init: function () {
				if ($(window).width() < 641) {
					initAccordion();
				}
			},
		};
	})();

	// Export
	window.AMFooter = AMFooter;
})(jQuery, this, this.document);
