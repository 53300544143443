(function ($, window, document, undefined) {
	var AMAdress = (function () {
		var select2Cities = null;
		var select2Countries = null;

		var initSelect2 = function () {
			select2Cities = $("select[name=select-cities]").select2({
				language: "he",
				dropdownParent: $("select[name=select-cities]").parent(),
			});

			select2Countries = $("select[name=select-countries]").select2({
				language: "he",
				dropdownParent: $("select[name=select-countries]").parent(),
			});

			// $("select[name=select-cities]").on("select2:select", function (e) {
			// 	// var data = e.params;
			// 	// console.log($(this));

			// 	$(this)
			// 		.parent()
			// 		.find(".select2-selection__choice")
			// 		.last()
			// 		.after(
			// 			$("<li/>", {
			// 				text: $(this).select2("data").length,
			// 				class: "select2-selection__choice-count",
			// 			})
			// 		);
			// });
		};

		return {
			/**
			 * инициализация модуля
			 */
			init: function () {
				initSelect2();
			},
		};
	})();

	// Export
	window.AMAdress = AMAdress;
})(jQuery, this, this.document);
