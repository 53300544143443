(function ($, window, document, undefined) {
	var AMLogin = (function () {
		var initVisible = function () {
			var btn = document.getElementById("change-visible");
			var inputPsw = document.getElementById("visible-pass");

			btn.addEventListener("click", function () {
				if (inputPsw.type === "password") {
					inputPsw.type = "text";
					this.classList.remove("not-visible");
				} else {
					inputPsw.type = "password";
					this.classList.add("not-visible");
				}
			});
		};

		return {
			/**
			 * инициализация модуля
			 */
			init: function () {
				// initVisible();
			},
		};
	})();

	// Export
	window.AMLogin = AMLogin;
})(jQuery, this, this.document);
